<template>
  <div ref="root" :style="style"></div>
</template>

<script>
import echarts from "../charts";
export default {
  props: { control: Object, isView: Boolean },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    style() {
      let result = JSON.parse(JSON.stringify(this.control.style));
      delete result["color"];
      return result;
    },
  },
  mounted() {
    this.change();
  },
  beforeDestroy() {
    if (this.chart) {
      let dom = this.chart.getDom();
      this.chart.dispose();
      if (dom) this.$refs.root.removeChild(dom);
      this.chart = null;
    }
  },
  watch: {
    "control.size": {
      handler() {
        this.change();
      },
      deep: true,
    },
    "control.style": {
      handler() {
        this.change();
      },
      deep: true,
    },
    "control.config": {
      handler() {
        this.change();
      },
      deep: true,
    },
  },
  methods: {
    change() {
      if (!this.chart) {
        let div = document.createElement("div");
        this.$refs.root.appendChild(div);
        this.chart = echarts.init(div, null, {
          width: this.control.size.width,
          height: this.control.size.height,
        });
      } else {
        this.chart.resize({
          width: this.control.size.width,
          height: this.control.size.height,
        });
      }
      this.chart.setOption(
        {
          tooltip: {
            formatter: "{c}" + this.control.config.unit,
          },
          series: [
            {
              type: "gauge",
              startAngle: this.control.config.startAngle,
              endAngle: this.control.config.endAngle,
              min: this.control.config.min,
              max: this.control.config.max,
              radius: this.control.config.gaugeRadius,
              center: this.control.config.gaugeCenter.split(","),
              splitNumber: this.control.config.splitNumber,
              axisLine: {
                show: false,
              },
              axisTick: {
                lineStyle: {
                  color: this.control.style.color,
                },
              },
              splitLine: {
                lineStyle: {
                  color: this.control.style.color,
                },
              },
              axisLabel: {
                color: this.control.style.color,
                fontSize: this.control.style["font-size"],
              },
              detail: {
                show: false,
              },
              pointer: {
                icon: "triangle",
                length: "70%",
                width: 8,
                itemStyle: {
                  color: this.control.style.color,
                },
              },
              anchor: {
                show: true,
                showAbove: true,
                size: 6,
                itemStyle: {
                  borderColor: this.control.style.color,
                  borderWidth: 6,
                },
              },
              data: [
                {
                  value: this.control.config.value,
                },
              ],
            },
          ],
        },
        !this.isView
      );
    },
  },
};
</script>
